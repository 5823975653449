import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SharedStateProvider } from "sharedStateProvider";
import "index.scss";
import MainPage from "components/main_page";
import NewApp from "components/new_app";
import reportWebVitals from "reportWebVitals";

const App = () => {
  return (
    <Router>
      <SharedStateProvider>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/create_app" element={<NewApp />} />
          <Route path="/edit_app" element={<NewApp />} />
        </Routes>
      </SharedStateProvider>
    </Router>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
