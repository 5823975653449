import { Api } from "api";

export const getAuthLink: any = async (payload: any) => {
  const res = await Api.post("/auth/v1/ap/link", payload);
  return res.data;
};

export const getToken: any = async (payload: any) => {
  const res = await Api.post("/auth/v1/ap/login", payload);
  localStorage.setItem("accessToken", res.data.access.hash);
  localStorage.setItem("refreshToken", res.data.refresh.hash);
  return res.data;
};

export const getMe: any = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get("/auth/v1/me", { headers });
  return res.data;
};

export const refreshToken: any = async () => {
  const refresh = localStorage.getItem("refreshToken");
  const res = await Api.post("/auth/v1/refresh", { token: refresh });
  return res.data;
};
