import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import { Drawer, Flex, Alert, Button, Divider, Input, Skeleton, Popconfirm } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  FileImageTwoTone,
  KeyOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { UserSearchType, MiniAppType } from "types/miniApp";
import {
  addCollaborators,
  deleteCollaborators,
  findUser,
  getCollaborators,
  getMiniApp,
  requestMiniApp,
} from "api/miniapps";
import { useSharedState } from "sharedStateProvider";

export default function SideDrawer({
  appInfo,
  sideDrawer,
  setSideDrawer,
}: {
  appInfo?: MiniAppType;
  sideDrawer: boolean;
  setSideDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const { setEditApp, showNotification } = useSharedState();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [openedApp, setOpenedApp] = useState<MiniAppType>();
  const [collaborators, setCollaborators] = useState<string[]>([]);
  const [copiedSecketKey, setCopiedSecketKey] = useState<boolean>(false);
  const [isAddingCollaborator, setAddingCollaborator] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>("");

  const removeNonNumericCharacters = (input: string): string => {
    return input.replace(/[^\d]/g, "");
  };

  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedPhoneNumber = removeNonNumericCharacters(event.target.value);
    setPhone(cleanedPhoneNumber);
  };

  const closeSideDrawer = () => {
    setOpenedApp(undefined);
    setSideDrawer(false);
    setAddingCollaborator(false);
    setPhone("");
  };

  const initAppInfo = async () => {
    try {
      const appData = await getMiniApp(appInfo?.id);
      setOpenedApp(appData);
      const collabData = await getCollaborators(appInfo?.id);
      setCollaborators(collabData);
    } catch (err: any) {
      showNotification({
        title: "Ошибка при загрузке приложения",
        description: "Не удалось загрузить данные о приложении",
        style: "error",
        duration: 3,
      });
      closeSideDrawer();
    }
  };

  const editApp = () => {
    setEditApp(openedApp);
    closeSideDrawer();
    navigate("edit_app");
  };

  const sendToReview = async () => {
    setLoading(true);
    requestMiniApp(openedApp?.id)
      .then(() => {
        showNotification({
          title: "Модерация",
          description: "Мини-приложение успешно отправлено на модерацию",
          style: "success",
          duration: 3,
        });
      })
      .catch((err: any) => {
        showNotification({
          title: "Ошибка",
          description: err?.response?.data?.error || "Возникла ошибка при отправке на модерацию",
          style: "error",
          duration: 5,
        });
      })
      .finally(() => setLoading(false));
  };

  const copySecretKey = () => {
    navigator.clipboard.writeText(openedApp ? openedApp.secret_key : "");
    setCopiedSecketKey(true);
    setTimeout(() => {
      setCopiedSecketKey(false);
    }, 1000);
  };

  const toggleAddingCollaborator = () => {
    setAddingCollaborator(!isAddingCollaborator);
    if (isAddingCollaborator) {
      setPhone("");
    }
  };

  const addCollaborator = async () => {
    try {
      setLoading(true);
      const USER: UserSearchType = await findUser(phone);

      if (USER) {
        await addCollaborators(appInfo?.id, {
          collaborators: [USER.id],
        });
        initAppInfo();
        setPhone("");
        showNotification({
          title: "Коллаборатор успешно добавлен",
          description: `${USER.first_name} ${USER.last_name} добавлен как коллаборатор`,
          style: "success",
          duration: 3,
        });
      } else {
        showNotification({
          title: "Ошибка при добавлении коллаборатора",
          description: "Пользователь не найден",
          style: "warning",
          duration: 3,
        });
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showNotification({
        title: "Ошибка",
        description: err?.response?.data?.error || "Возникла ошибка при добавлении пользователя",
        style: "error",
        duration: 5,
      });
    }
    // }
  };

  const onDeleteCollaborator = async (user: UserSearchType) => {
    try {
      setLoading(true);
      await deleteCollaborators(appInfo?.id, {
        collaborators: [user.id],
      });
      initAppInfo();
      setLoading(false);
      showNotification({
        title: "Коллаборатор успешно удалён",
        description: `${user.first_name} ${user.last_name} удалён`,
        style: "success",
        duration: 3,
      });
    } catch (err) {
      setLoading(false);
      showNotification({
        title: "Ошибка",
        description: "Возникла ошибка при удалении коллаборатора",
        style: "error",
        duration: 5,
      });
    }
  };

  const deletingBtn = (user: UserSearchType) => {
    return (
      <Popconfirm
        title="Подтвердите действие"
        description={`Удалить ${user.first_name} ${user.last_name} из списка коллабораторов?`}
        onConfirm={() => onDeleteCollaborator(user)}
        okText="Удалить"
        cancelText="Отмена"
      >
        <CloseOutlined className="pointer" />
      </Popconfirm>
    );
  };

  useEffect(() => {
    sideDrawer && initAppInfo();
  }, [sideDrawer]);

  return (
    <Drawer
      title="Данные приложения"
      placement="right"
      closable={true}
      onClose={closeSideDrawer}
      open={sideDrawer}
      destroyOnClose
    >
      {!openedApp ? (
        <Skeleton />
      ) : (
        <div className="d-flex flex-column" style={{ gap: 24 }}>
          <div className="d-flex justify-between">
            {openedApp.logo_url ? (
              <img src={openedApp.logo_url} alt="" height={120} width={120} />
            ) : (
              <FileImageTwoTone
                twoToneColor="#EF7F1A"
                style={{ fontSize: 80, justifyContent: "center", width: 120, height: 120 }}
              />
            )}
            <Alert
              message={openedApp?.mode === "production" ? "В КАТАЛОГЕ" : "РЕЖИМ РАЗРАБОТКИ"}
              type={openedApp?.mode === "production" ? "success" : "info"}
              style={{ height: "min-content", width: "fit-content", fontSize: 12, fontWeight: 700 }}
            />
          </div>
          <div className="d-flex flex-column" style={{ gap: 8 }}>
            <Button onClick={editApp} disabled={isLoading}>
              Редактировать
            </Button>
            <Button disabled={true || isLoading}>Связаться с модератором</Button>
            {openedApp?.mode === "development" && (
              <Button onClick={sendToReview} disabled={isLoading}>
                Отправить на модерацию
              </Button>
            )}
          </div>
          <div className="d-flex flex-column" style={{ gap: 16 }}>
            <div>
              <div>ID мини-приложения</div>
              <div>{openedApp.id}</div>
            </div>
            <div>
              <div>ID создателя</div>
              <div>{openedApp.user_id}</div>
            </div>
            <div>
              <div>Создатель</div>
              <div>{openedApp.username}</div>
            </div>
          </div>
          <Divider style={{ margin: 0 }} />
          <div className="d-flex flex-column" style={{ gap: 16 }}>
            <div>
              <div>Название (RU): {openedApp?.names.ru}</div>
              <div>Описание (RU): {openedApp?.descriptions.ru}</div>
            </div>
            <div>
              <div>Название (KZ): {openedApp?.names.kz}</div>
              <div>Описание (KZ): {openedApp?.descriptions.kz}</div>
            </div>
            <div>
              <div>Название (EN): {openedApp?.names.en}</div>
              <div>Описание (EN): {openedApp?.descriptions.en}</div>
            </div>
          </div>
          <div>
            <h4 style={{ margin: "0 0 12px" }}>Ключ безопасности</h4>
            <Flex gap={8}>
              <Input.Password value={openedApp?.secret_key} prefix={<KeyOutlined />} readOnly />
              <Button onClick={copySecretKey}>{copiedSecketKey ? <CheckOutlined /> : <CopyOutlined />}</Button>
            </Flex>
          </div>
          <div>
            <Flex gap={8} justify="space-between">
              <h4 style={{ margin: "0 0 12px" }}>Коллабораторы</h4>
              <div className="pointer" style={{ color: "#91caff" }} onClick={toggleAddingCollaborator}>
                Добавить
              </div>
            </Flex>
            <Flex vertical gap={8}>
              {collaborators.length ? (
                collaborators.map((user: UserSearchType | any) => (
                  <Input
                    key={user.id}
                    placeholder="Список пуст"
                    value={`${user.first_name} ${user.last_name}`}
                    prefix={<UserOutlined />}
                    suffix={deletingBtn(user)}
                    readOnly
                  />
                ))
              ) : (
                <Flex justify="center">Коллабораторы не добавлены</Flex>
              )}
            </Flex>
            {isAddingCollaborator && (
              <>
                <h4 style={{ margin: "12px 0" }}>Добавление коллаборатора</h4>
                <Flex gap={8}>
                  <InputMask mask="+7-(999)-999-99-99" maskChar="_" value={phone} onChange={onPhoneChange} />
                  <Button disabled={phone.length !== 11} loading={isLoading} onClick={addCollaborator}>
                    Добавить
                  </Button>
                </Flex>
              </>
            )}
          </div>
          <div></div>
        </div>
      )}
    </Drawer>
  );
}
