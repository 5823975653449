import type { CheckboxValueType } from "antd/es/checkbox/Group";

export type AppFormType = {
  nameRu: string;
  nameKz: string;
  nameEn: string;
  descriptionRu: string;
  descriptionKz: string;
  descriptionEn: string;
  bridgeMethods: CheckboxValueType[];
  link: string;
  logo: string;
  logo_url: string;
};

export const EmptyAppForm: AppFormType = {
  nameRu: "",
  nameKz: "",
  nameEn: "",
  descriptionRu: "",
  descriptionKz: "",
  descriptionEn: "",
  bridgeMethods: [],
  link: "",
  logo: "",
  logo_url: "https://media-cdn.aitu.io/api/v2/media/download/avatar/013bc1b6-e5c2-11ee-9753-6e4e2a6ecf16",
};

export type AppPayloadType = {
  names: {
    ru: string;
    kz: string;
    en: string;
  };
  descriptions: {
    ru: string;
    kz: string;
    en: string;
  };
  bridge_methods: CheckboxValueType[];
  link: string;
  logo: string;
};

export type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};
