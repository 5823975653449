import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useRef,
  useEffect,
  ReactNode,
} from "react";
import AuthPage from "components/auth_page";
import {
  BridgeMethodResponseType,
  BridgeMethodType,
  MiniAppType,
  UserType,
} from "types/miniApp";
import { getBridgeMethods } from "api/bridge";
import NotificationComponent, {
  NotificationMethods,
} from "components/notification";
import { NotificationProps } from "components/notification/types";
import LoadingOverlay from "components/loading_overlay";
import { getMiniApps } from "api/miniapps";
import { getMe } from "api/auth";

interface SharedState {
  accessToken: string | null;
  setAccessToken: React.Dispatch<React.SetStateAction<string | null>>;
  refreshToken: string | null;
  setRefreshToken: React.Dispatch<React.SetStateAction<string | null>>;
  logout: () => void;
  me: UserType | undefined;
  initGetMe: () => Promise<void>;
  miniApps: MiniAppType[] | [];
  initGetMiniApps: () => Promise<void>;
  editApp: MiniAppType | undefined;
  setEditApp: React.Dispatch<React.SetStateAction<MiniAppType | undefined>>;
  bridgeMethodList: BridgeMethodType[] | [];
  setBridgeMethodList: React.Dispatch<
    React.SetStateAction<BridgeMethodType[] | []>
  >;
  isLoadingOverlay: boolean;
  setLoadingOverlay: React.Dispatch<React.SetStateAction<boolean>>;
  showNotification: (data: NotificationProps) => void;
}

const SharedStateContext = createContext<SharedState | undefined>(undefined);

export const SharedStateProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const NotificationRef = useRef<NotificationMethods>(null);

  const localAccessToken = localStorage.getItem("accessToken");
  const localRefreshToken = localStorage.getItem("refreshToken");
  const [accessToken, setAccessToken] = useState<string | null>(
    localStorage.getItem("accessToken")
  );
  const [refreshToken, setRefreshToken] = useState<string | null>(
    localStorage.getItem("refreshToken")
  );

  const [me, setMe] = useState<UserType>();

  const [miniApps, setMiniApps] = useState<MiniAppType[]>([]);

  const [editApp, setEditApp] = useState<MiniAppType | undefined>(undefined);

  const [bridgeMethodList, setBridgeMethodList] = useState<BridgeMethodType[]>(
    []
  );

  const [isLoadingOverlay, setLoadingOverlay] = useState<boolean>(false);

  const initGetMe = async () => {
    getMe()
      .then((res: any) => {
        setMe(res);
      })
      .catch((err: any) => console.log(err));
  };

  const initGetMiniApps = async () => {
    getMiniApps()
      .then((res: any) => {
        setMiniApps(res);
      })
      .catch((err: any) => console.log(err));
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setAccessToken(null);
    setRefreshToken(null);

    showNotification({
      title: "Сессия завершена",
      description: "Пожалуйста, пройдите авторизацию",
      style: "warning",
      duration: 3,
    });
  };

  const showNotification = (data: NotificationProps) => {
    if (NotificationRef.current) {
      NotificationRef.current.openNotification({
        title: data.title,
        description: data.description,
        style: data.style,
        duration: data.duration,
      });
    }
  };

  const value: SharedState = {
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
    logout,
    me,
    initGetMe,
    miniApps,
    initGetMiniApps,
    editApp,
    setEditApp,
    bridgeMethodList,
    setBridgeMethodList,
    isLoadingOverlay,
    setLoadingOverlay,
    showNotification,
  };

  const initBridgeMethods = async () => {
    try {
      const firstPageData: BridgeMethodResponseType = await getBridgeMethods();
      const totalPages = Math.ceil(firstPageData.total / firstPageData.size);

      const pageDataPromises = [];
      for (let i = 2; i <= totalPages; i++) {
        let response: BridgeMethodResponseType = await getBridgeMethods(i);
        pageDataPromises.push(response.data);
      }

      const otherPagesData = await Promise.all(pageDataPromises);
      const allData = [firstPageData.data, ...otherPagesData].flat();

      setBridgeMethodList(allData);
    } catch (err: any) {
      err.response.status !== 401 &&
        showNotification({
          title: "Ошибка",
          description:
            err?.response?.data?.error || "Не удалось загрузить данные",
          style: "error",
          duration: 5,
        });
    }
  };

  const hasToken: boolean = useMemo(() => {
    if (localAccessToken !== null || localRefreshToken !== null) {
      setAccessToken(localAccessToken);
      setRefreshToken(localRefreshToken);
      return true;
    } else return false;
  }, [localAccessToken, localRefreshToken]);

  useEffect(() => {
    accessToken !== null && Promise.all([initBridgeMethods()]);
  }, []);

  return (
    <SharedStateContext.Provider value={value}>
      {hasToken ? children : <AuthPage />}
      <LoadingOverlay showLoadingOverlay={isLoadingOverlay} />
      <NotificationComponent ref={NotificationRef} />
    </SharedStateContext.Provider>
  );
};

export const useSharedState = (): SharedState => {
  const context = useContext(SharedStateContext);
  if (!context) {
    throw new Error("useSharedState must be used within a SharedStateProvider");
  }
  return context;
};
