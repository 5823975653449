import "./styles.scss";
import { Spin } from "antd";

export default function LoadingOverlay({
  showLoadingOverlay,
}: {
  showLoadingOverlay: boolean;
}) {
  return showLoadingOverlay ? (
    <div className="overlay">
      <Spin size="large" />
    </div>
  ) : (
    <></>
  );
}
