import { Api } from "api";

export const getAppNotifications: any = async (appId: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`/notifications/v1/${appId}/mini_app_notifications`, { headers });
  return res.data;
};

export const createNotification: any = async (appId: string, payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.post(`/notifications/v1/${appId}/mini_app_notifications`, payload, {
    headers,
  });
  return res.data;
};
