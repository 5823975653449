import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import { Flex, Button } from "antd";
import { getAuthLink, getToken } from "api/auth";
import { useSharedState } from "sharedStateProvider";

export default function AuthPage() {
  const navigate = useNavigate();
  const { setLoadingOverlay, showNotification } = useSharedState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>("");

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  const removeNonNumericCharacters = (input: string): string => {
    return input.replace(/[^\d]/g, "");
  };

  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedPhoneNumber = removeNonNumericCharacters(event.target.value);
    setPhone(cleanedPhoneNumber);
  };

  const getLink = async () => {
    try {
      setIsLoading(true);
      const payload = { phone: phone, redirect_uri: process.env.REACT_APP_REDIRECT_URI };
      const res = await getAuthLink(payload);
      window.open(res.link, "_self");
    } catch (err: any) {
      showNotification({
        title: "Ошибка перенаправления",
        description:
          err?.response?.data?.error ||
          "Не удалось перенаправить данные в Aitu Passport",
        style: "error",
        duration: 3,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onTokenGet = async () => {
    try {
      setLoadingOverlay(true);
      await getToken({ code: code, redirect_uri: process.env.REACT_APP_REDIRECT_URI });
      navigate("/", { replace: true });
      setLoadingOverlay(false);
    } catch (err: any) {
      setLoadingOverlay(false);
      showNotification({
        title: "Авторизация не пройдена",
        description:
          err?.response?.data?.error || "Произошла ошибка во время авторизации",
        style: "error",
        duration: 10,
      });
    }
  };

  useEffect(() => {
    code && onTokenGet();
  }, [code]);

  useEffect(() => {
    document.title = "Cabinet | Авторизация";
  }, []);

  return (
    <Flex
      vertical
      justify="center"
      align="center"
      gap={32}
      style={{ height: "100vh" }}
    >
      <span style={{ fontSize: 20, textAlign: "center" }}>
        Для входа в личный кабинет мини-приложений требуется авторизация
      </span>
      <InputMask
        mask="+7-(999)-999-99-99"
        maskChar="_"
        value={phone}
        onChange={onPhoneChange}
      />
      <Button
        onClick={getLink}
        loading={isLoading}
        disabled={phone.length !== 11}
      >
        Авторизация
      </Button>
    </Flex>
  );
}
